.navbar-vertical {
    &.navbar-expand-lg {
        border: none !important;
        box-shadow: 2px 0 13px rgb(0 0 0 / 5%) !important;
    }
}
nav {
    &#topbar {
        box-shadow: 0 2px 4px 0 rgb(43 43 43 / 10%);
        border-bottom: none !important;
    }
}
.navbar-brand {
    img {
        height: 40px;
    }
}

.navbar-nav {
    .collapse {
        &.show {
            .nav-link {
                &.active {
                    color: #39a536;
                    font-weight: 600;
                }
            }
        }
    }
}

.file-uploader {
    input[class="file-upload"] {
        display: none;
    }

    label {
        background: #fff;
        width: 100%;
        max-width: 400px;
        height: auto;
        aspect-ratio: 9 / 10;
        background-image: url("../../../public/icons/file-upload.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 15%;
        color: transparent;
        margin: auto;
        width: 100%;
        position: relative;
        border: 2px dashed #000;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            border: 2px dashed #000;
        }
    }
    &:hover {
        label {
            &:after {
                display: block;
                content: "(jpg, jpeg, png, svg)";
                width: calc(100% - 12px);
                line-height: 24px;
                position: absolute;
                font-size: 12px;
                line-height: 16px;
                color: #000;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                font-family: "Inter";
                top: 60%;
                transform: translateY(12px);
            }
        }
    }
    &.full {
        label {
            max-width: 100%;
            background-size: 5%;
        }
    }
}

.scroll-y-label {
    scrollbar-width: thin;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.2);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
}

#commonListTable {
    overflow-y: scroll;
    width: 100%;
    height: calc(100vh - 150px);
    display: inline-block;
    max-width: 100%;
}

table {
    .logo {
        width: auto;
        height: 32px;
        object-fit: contain;
    }
    tr {
        td {
            &:last-child {
                img {
                    width: 24px;
                    height: 24px;
                    max-width: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .file-uploader {
        label {
            max-width: 150px;
        }
    }
}
