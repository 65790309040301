.uploaded-logo {
    height: 172px;
    width: auto;
    object-fit: contain;
    &.product {
        height: 100px;
        padding: 8px;
        border: 1px solid #e4e7eb;
        margin-right: 8px;
    }
}
