.question-form-container {
    .options {
        max-width: 600px;
        margin: 0 auto;
        .single-option {
            padding: 8px 16px 16px;
            border-radius: 8px;
            border: 1px solid #e7eaf0;
            margin-bottom: 16px;
        }
    }
}

.single-question {
    input {
        &.question-title {
            max-width: 700px;
        }
        &.amount {
            max-width: 230px;
        }
    }
}
