.dark {
  color: #000;
}
.w-100 {
  width: 100%;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.regular-12 {
  font-size: 12px;
  line-height: 16px;
}
.regular-14 {
  font-size: 14px;
  line-height: 20px;
}
.form-container {
  max-width: 760px;
  margin: 0 auto;
  .form-row {
    border: 1px solid #e4e7eb;
    background: #ffffff !important;
    margin-bottom: 24px;
    .form-check-label {
      font-weight: 400;
      color: #525f7f;
      cursor: pointer;
    }
    .form-check-input {
      border: 1px solid #39a536;
      &:checked {
        background-color: #39a536;
        border-color: #39a536;
      }
      &:focus {
        box-shadow: 0 0 0 3px rgba(57, 165, 54, 0.25);
      }
    }
  }
}
input {
  &.common-list-search {
    height: 32px;
    max-width: 220px;
    font-size: 12px;
  }
}

table {
  &.table {
    td {
      white-space: normal;
    }
  }
}

button {
  &.green {
    background: #39a536;
    color: #fff;
    &:hover {
      background: #39a536;
      color: #fff;
      opacity: 0.7;
    }
  }
  &.common-serach {
    width: 52px;
    padding: 0px;
    height: 32px;
    margin-left: -10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    img {
      height: 20px;
      height: 20px;
    }
  }
}

p {
  &.empty-state {
    text-align: center;
    padding: 150px 0;
    color: #6a7181;
  }
}

.color-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: inline-block;
  border-radius: 100px;
}

.weight-500 {
  font-weight: 500;
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 2px;
    }
    .modal-header {
      padding: 14px 20px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
    .modal-footer {
      padding: 8px 20px;
      border-top: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}
