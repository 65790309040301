.productlist-top-header {
  width: 100%;
  // .search{
  //     width: 248px;
  // }
  // .type-filters{
  //     width: calc(100% - 248px);
  // }
  .form-select {
    width: auto;
    margin-right: 12px;
  }
  .categorie-type {
    position: relative;
    .child-categories {
      padding: 16px;
      background: #fff;
      position: absolute;
      top: 100%;
      width: 220px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.04);
      .form-select {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  button {
    &.add {
      display: flex;
    }
  }
}
.product-details {
  p {
    color: #000;
    font-size: 14px;
    line-height: 20px;
  }
  textarea {
    width: 100%;
    min-width: 100%;
    display: block;
  }
  span {
    &.weight-500 {
      display: inline-block;
      margin-left: 4px;
      margin-top: 4px;
    }
  }
  .first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .feature-image {
      position: relative;
      max-width: 240px;
      img {
        max-width: 240px;
        height: auto;
        object-fit: contain;
      }
      .reupload-image {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.product-varients {
  table {
    tr {
      td {
        &:first-child {
          img {
            width: auto;
            height: 60px;
          }
        }
        .file-uploader {
          label {
            width: 130px;
            aspect-ratio: 4 / 2;
            margin-left: 12px;
          }
          &:hover {
            label {
              background-size: 0;
              &::after {
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
        select {
          width: 150px;
        }
      }
    }
  }
}

.capacity-and-battery {
  .capacity {
    width: 50%;
    padding-right: 20px;
    border-right: 1px solid #e7eaf0;
  }
  .battery {
    width: 50%;
    padding-left: 20px;
  }
  .variants {
    .single-variant {
      margin-bottom: 12px;
    }
  }
}

.questions-select-modal {
  .modal-dialog {
    max-width: 720px;
    .modal-body {
      .question-list {
        .single-question {
          margin-bottom: 20px;
          .select {
            margin-right: 8px;
            position: relative;
            top: -2px;
          }
          .title {
            p {
              color: #000;
              font-weight: 500;
            }
          }
          .types {
            max-width: 400px;
            margin-left: 45px;
          }
          .options {
            margin-left: 45px;
            .single-option {
              margin-bottom: 4px;
              .option-values {
                margin-left: 8px;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
}
